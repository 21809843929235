import React from "react"
import Base from "../layout/Base"

const Despacho = () => {
    return (
        <Base>
              <div id='collection-component-1651595166161' style={{display: "none"}}></div>
            <div className="text-page-title"><p><b>Despachos</b></p></div>
            <div class="text-page-content">
                <p>&nbsp;</p>
                <p><span style={{fontWeight : "400;"}}>Cuando compras en GreenCare, puedes elegir el lugar donde recibir tus productos, o si lo prefieres, puedes venir a retirar tu pedido a nuestras oficinas. Al seleccionar este m&eacute;todo de entrega, te enviaremos la direcci&oacute;n para que vengas a retirarlo. Si no estabas cuando llegamos a dejar el producto a tu casa u oficina, no te preocupes, nos coordinaremos contigo para una nueva entrega. En GreenCare, despachamos&nbsp;a gran parte de&nbsp;Chile y, por el momento, no realizamos env&iacute;os al extranjero.</span></p>
                <p><span style={{fontWeight : "400;"}}>ENTREGA DE PRODUCTOS</span></p>
                <p><strong>Despacho:</strong><span style={{fontWeight : "400;"}}><br /><br /></span></p>
                <p><span style={{fontWeight : "400;"}}><br /></span><span style={{fontWeight : "400;"}}>Puedes solicitar que enviemos tu pedido al domicilio indicado al momento de realizar tu compra.</span></p>
                <p><span style={{fontWeight : "400;"}}>Si la direcci&oacute;n corresponde a un departamento o casa de condominio, y el cliente pidi&oacute; dejar el pedido en conserjer&iacute;a, es tu responsabilidad dar aviso para que sea recibido y registrado por la persona de turno. En caso contrario, no se responder&aacute; por la p&eacute;rdida de los productos.</span><span style={{fontWeight : "400;"}}><br /><br /></span></p>
                <p><span style={{fontWeight : "400;"}}><br /></span><span style={{fontWeight : "400;"}}>*</span><strong>IMPORTANTE</strong><span style={{fontWeight : "400;"}}>: Las personas que compran con la opci&oacute;n de&nbsp;</span><strong>retiro en oficina y no retiran en un plazo de 15 d&iacute;as calendario</strong><span style={{fontWeight : "400;"}}>, recibir&aacute;n un e-mail informando de la anulaci&oacute;n. En este caso, el monto de la compra puede traspasarse a cr&eacute;ditos GreenCare o ser devuelto a su cuenta bancaria, de acuerdo a la preferencia personal.</span></p>
                <p><span style={{fontWeight : "400;"}}>PLAZOS DE ENTREGA</span></p>
                <p><span style={{fontWeight : "400;"}}>Nuestros plazos de entrega comienzan a correr una vez que recibimos tu pedido, comprometi&eacute;ndonos a despacharlo dentro de los tiempos establecidos. Los tiempos de env&iacute;o dependen de la regi&oacute;n en la que vives:</span></p>
                <ul>
                <li style={{fontWeight : "400;"}}><span style={{fontWeight : "400;"}}>Si vives en la Regi&oacute;n Metropolitana, las entregas las realizamos de lunes a viernes entre 9:00 y 18:00 horas, dentro de 2 a 10 d&iacute;as h&aacute;biles (excepto v&iacute;a ServiSenior)</span></li>
                <li style={{fontWeight : "400;"}}><span style={{fontWeight : "400;"}}>Si vives en regiones, las entregas se realizan de lunes a viernes entre 9:00 y 18:00 horas y el tiempo depender&aacute; del lugar donde est&eacute;s, pero no debiera tardar m&aacute;s de 15 d&iacute;as h&aacute;biles.</span></li>
                </ul>
                <p>&nbsp;</p>
                <p><span style={{fontWeight : "400;"}}>COSTOS DE DESPACHO</span></p>
                <p><span style={{fontWeight : "400;"}}>En GreenCare queremos que todas las personas puedan adquirir nuestros productos y por ello despachamos lo que necesites donde sea que te encuentres. Enviamos desde Arica a Punta Arenas. El costo de despacho en Regi&oacute;n Metropolitana depender&aacute; del volumen de tu pedido, mientras que el valor de despacho en Regiones, depender&aacute; de la regi&oacute;n y la localidad en la que te encuentres. Podr&aacute;s ver el detalle del env&iacute;o una vez que selecciones la regi&oacute;n al momento de realizar tu pago.</span></p>
                <p><span style={{fontWeight : "400;"}}>Hacemos dos intentos de entrega. En caso de ingresar una direcci&oacute;n de dif&iacute;cil acceso o localizaci&oacute;n (como podr&iacute;a ser &ldquo;Camino Azul, km 3, casa roja, Caj&oacute;n del Maipo&rdquo; u otras similares), se har&aacute; el intento de entrega en dicho domicilio. Sin embargo, si la agencia encargada del despacho no encuentra dicha direcci&oacute;n, el pedido volver&aacute; a GreenCare, situaci&oacute;n en la cual solicitaremos al cliente una direcci&oacute;n de f&aacute;cil acceso para realizar un nuevo despacho que tendr&aacute; costo adicional.</span></p>
                <p><span style={{fontWeight : "400;"}}>ESTADO DE MI PEDIDO</span></p>
                <p><span style={{fontWeight : "400;"}}>Para saber el estado de tu pedido, puedes ingresar a nuestro sitio, en la secci&oacute;n Mi cuenta y buscar tus compras en la secci&oacute;n &ldquo;Mis pedidos&rdquo;, donde estar&aacute; actualizada la informaci&oacute;n de tu env&iacute;o. Si tu pedido no ha llegado, cont&aacute;ctanos a trav&eacute;s de nuestro formulario de contacto o a trav&eacute;s de Facebook. Ojal&aacute; nunca tengas que leer esta pregunta, ya que trabajamos con el &lt;3 para que esto no ocurra.</span></p>

            </div>
        </Base>
    )
}
export default Despacho;
