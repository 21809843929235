import React from "react"
import Base from "../layout/Base"

const Quees = () => {
    return (
        <Base>
              <div id='collection-component-1651595166161' style={{display: "none"}}></div>
            <div className="text-page-title"><p><b>¿Que es GreenCare?</b></p></div>
            <div class="text-page-content">
            <p>Greencare nace bajo la premisa de convertirnos en un &nbsp;un referente en el mundo del cuidado personal, desde la innovaci&oacute;n y el cuidado respetuoso con el planeta, ofreciendo productos de calidad que certifican nuestro compromiso con quienes buscan en nosotros todo lo que necesitan para hacer de su hogar y su vida un espacio m&aacute;s c&oacute;modo y sustentable.</p>
            </div>
        </Base>
    )
}
export default Quees;
